<template>
  <v-container class="pt-0">
    <v-card elevation="0">
      <v-card-title class="pb-0">
        {{ $t('courierSet.shipmentMethod.title').toUpperCase() }}
      </v-card-title>
      <v-container>
        <v-row class="pb-5">
          <v-divider />
        </v-row>
        <v-row>
          <v-col class="pb-0">
            <text-field
              v-model="configurationName"
              :title="$t('courierSet.shipmentMethod.configurationName')"
              rules="required"
              @input="update"
            />
          </v-col>
          <v-col class="pb-0">
            <text-field
              v-model="id"
              :title="$t('courierSet.shipmentMethod.configurationId')"
              rules="required"
              :disabled="true"
              @input="update"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <select-field
              v-model="courierCode"
              :title="$t('courierSet.shipmentMethod.deliverySpeditor')"
              rules="required"
              :filed-items="SHIPMENT_METHOD_CODES"
              disabled
            />
          </v-col>
        </v-row>
      </v-container>
    </v-card>
    <v-card
      elevation="0"
      class="mt-5"
    >
      <v-card-title class="pb-0">
        {{ $t('courierSet.authorizationData.title').toUpperCase() }}
      </v-card-title>
      <v-container>
        <v-row class="pb-5">
          <v-divider />
        </v-row>
        <v-row>
          <v-col class="pt-0">
            <text-field
              v-model="url"
              :title="$t('courierSet.authorizationData.urlAddress')"
              :rules="'required|url'"
              @input="update"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pt-0">
            <text-field
              v-model="shipperId"
              :title="$t('courierSet.pekaesShipperId')"
              rules="required|numeric"
              @input="update"
            />
          </v-col>
          <v-col class="pb-0">
            <text-field
              v-model="password"
              :title="$t('courierSet.authorizationData.password')"
              type="password"
              rules="required"
              @input="update"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-container>
</template>

<script>
import TextField from '@/components/inputs/TextField.vue';
import { v4 as uuidv4 } from 'uuid';
import { mapGetters } from 'vuex';
import { SHIPMENT_METHOD_CODES } from '@/constants/ShipmentMethodCodes.js';

export default {
  components: {
    TextField,
  },
  props: {
    isNew: { type: Boolean, default: false },
  },
  data: () => ({
    SHIPMENT_METHOD_CODES,
    courierCode: '',
    id: null,
    tenantId: null,
    configurationName: null,
    password: null,
    url: '',
    shipperId: null,
  }),
  computed: {
    ...mapGetters('spedition', ['getConfigurationSet']),
  },
  mounted() {
    if (!this.isNew) {
      this.getDataFromStore();
    } else {
      this.id = uuidv4();
      this.url = 'http://ugo.pekaes.com.pl/ugogate_001.asmx?wsdl';
    }
  },
  methods: {
    update() {
      const serviceAuthorization = {
        clientEntityState: this.isNew ? 4 : 3,
        id: this.id,
        tenantId: this.tenantId,
        configurationName: this.configurationName,
        password: this.password,
        shipperId: this.shipperId,
        url: this.url,
      };
      this.$emit('@input', serviceAuthorization);
    },
    getDataFromStore() {
      const { id, tenantId, configurationName, password, shipperId, url, courierCode } =
        this.getConfigurationSet();
      this.id = id;
      this.tenantId = tenantId;
      this.configurationName = configurationName;
      this.password = password;
      this.shipperId = shipperId;
      this.url = url;
      this.courierCode = courierCode || 'pekaes';
    },
  },
};
</script>

<style scoped lang="scss">
.col {
  padding-top: 0px;
  padding-bottom: 0px;
}
</style>
